import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { IonRow, IonCol, IonList, IonItem, IonInput, IonLabel, IonIcon, IonListHeader, IonCardContent } from '@ionic/react'
import { closeCircle } from 'ionicons/icons'

import EditableIonButton from 'components/basepaws/utils/EditableIonButton'
import content from 'util/content'
import { Formik } from 'formik'
import * as Yup from 'yup'
import services from 'util/services'
import styles from './EditProfileForm.module.scss'
import LoaderButton from 'components/basepaws/utils/LoaderButton/index'
import Toaster from 'components/basepaws/utils/Toaster'
import { emailRegex, invalidEmailErrorMessage, invalidPhoneErrorMessage } from 'util/constants'

const EditProfileForm = ({ user }) => {
  const registerSchema = Yup.object().shape({
  //   gender: Yup.string().required('Please input a name')
    phone_number: Yup.string().matches(/^[0-9]*$/, invalidPhoneErrorMessage),
    vet_email: Yup.string().email(invalidEmailErrorMessage).matches(emailRegex, invalidEmailErrorMessage)
  })

  const [edited, setEdited] = useState(false)
  const history = useHistory()

  const onCancel = () => {
    history.goBack()
  }

  const onSubmit = async (values, setSubmitting) => {
    setSubmitting(true)

    const userData = {
      ...user,
      first_name: values.first_name,
      last_name: values.last_name,
      phone_number: values.phone_number,
      vet: {
        clinic: values.vet_clinic,
        name: values.vet_name,
        email: values.vet_email
      }
    }

    try {
      console.log('updating user as: ', userData)
      await services.setUser(userData)
      setSubmitting(false)
    } catch (e) {
      console.log(e)
      setSubmitting(false)
    }
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        id: user?.id,
        first_name: user?.first_name,
        last_name: user?.last_name,
        email: user?.email,
        phone_number: user?.phone_number,
        vet_clinic: user?.vet?.clinic,
        vet_name: user?.vet?.name,
        vet_email: user?.vet?.email
      }}
      validateOnChange={true}
      validateOnBlur={true}
      validateOnMount={true}
      isInitialValid={true}
      validationSchema={registerSchema}
      onSubmit={(values, { setSubmitting }) => {
        setEdited(true)
        onSubmit(values, setSubmitting)
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValid,
        dirty
      }) => (
        <form onSubmit={handleSubmit}>
        <Toaster
          status={edited}
          onDidDismiss={() => {
            setEdited(false)
          }}
          header='Perfect!'
          message="Meow! Your profile was edited"
        />
          <IonCardContent className={styles.card_content}>
            <IonRow className="ion-justify-content-center ion-justify-content-between">
              <IonCol size-lg="5" size-md="12" size="12">
                <h3 className="Heading_3 text_sad_purple">{content.get('PROFILE.FORM.PERSONAL.TITLE')}</h3>
                <p className="Body_1 text_grey">{content.get('PROFILE.FORM.PERSONAL.DESCRIPTION')}</p>
              </IonCol>
              <IonCol size-lg="6" size-md="12" size="12">
                <IonList className="form ion-margin-top">
                  <IonItem lines="none" className="item-no-padding">
                    <IonListHeader className="ion-no-padding">
                      <IonLabel>
                        <h4 className="Heading_5 text_sad_purple">{content.get('PROFILE.FORM.PERSONAL.FIELD1')}</h4>
                      </IonLabel>
                    </IonListHeader>
                  </IonItem>
                  <IonItem lines="none" className="ion-margin-bottom item-no-padding">
                    <IonInput
                      type="text"
                      name="first_name"
                      onIonChange={handleChange}
                      onIonBlur={handleBlur}
                      value={values.first_name}
                    >
                    </IonInput>
                        {errors.first_name && touched.first_name
                          ? (
                          <IonItem className="ion-margin-bottom input-error-message">
                            <div className="input-error-label"><IonIcon icon={closeCircle} color="danger" slot="start"/>{errors.first_name}</div>
                          </IonItem>
                            )
                          : null}
                  </IonItem>
                  <IonItem lines="none" className="item-no-padding">
                      <IonListHeader className="ion-no-padding">
                        <IonLabel>
                          <h4 className="Heading_5 text_sad_purple">{content.get('PROFILE.FORM.PERSONAL.FIELD2')}</h4>
                        </IonLabel>
                      </IonListHeader>
                    </IonItem>
                    <IonItem lines="none" className="ion-margin-bottom item-no-padding">
                      <IonInput
                        type="text"
                        name="last_name"
                        onIonChange={handleChange}
                        onIonBlur={handleBlur}
                        value={values.last_name}
                      >
                      </IonInput>
                          {errors.last_name && touched.last_name
                            ? (
                            <IonItem className="ion-margin-bottom input-error-message">
                              <div className="input-error-label"><IonIcon icon={closeCircle} color="danger" slot="start"/>{errors.last_name}</div>
                            </IonItem>
                              )
                            : null}
                    </IonItem>
                    <IonItem lines="none" className="item-no-padding">
                    <IonListHeader className="ion-no-padding">
                      <IonLabel>
                        <h4 className="Heading_5 text_sad_purple">{content.get('PROFILE.FORM.PERSONAL.FIELD3')}</h4>
                      </IonLabel>
                    </IonListHeader>
                  </IonItem>
                  <IonItem lines="none" className="ion-margin-bottom item-no-padding">
                    {/* Disabled input */}
                    <IonInput
                      type="email"
                      name="email"
                      value={values.email}
                      disabled
                    >
                    </IonInput>
                        {errors.email && touched.email
                          ? (
                          <IonItem className="ion-margin-bottom input-error-message">
                            <div className="input-error-label"><IonIcon icon={closeCircle} color="danger" slot="start"/>{errors.email}</div>
                          </IonItem>
                            )
                          : null}
                  </IonItem>
                  <IonItem lines="none" className="item-no-padding">
                    <IonListHeader className="ion-no-padding">
                      <IonLabel>
                        <h4 className="Heading_5 text_sad_purple">{content.get('PROFILE.FORM.PERSONAL.FIELD4')} <span className="Lead_2 text_dark_grey">  •  {content.get('PROFILE.FORM.PERSONAL.LABEL_OPTIONAL')}</span></h4>
                      </IonLabel>
                    </IonListHeader>
                  </IonItem>
                  <IonItem lines="none" className="ion-margin-bottom item-no-padding">
                    <IonInput
                      type='tel'
                      name="phone_number"
                      onIonChange={handleChange}
                      onIonBlur={handleBlur}
                      value={values.phone_number}
                    >
                    </IonInput>
                  </IonItem>
                    {errors.phone_number
                      ? (
                      <IonItem className="ion-margin-bottom input-error-message">
                        <div className="input-error-label"><IonIcon icon={closeCircle} color="danger" slot="start"/>{errors.phone_number}</div>
                      </IonItem>
                        )
                      : null}
                </IonList>
              </IonCol>
            </IonRow>
            <IonRow className="ion-margin-vertical ion-justify-content-between">
              <IonCol size-lg="5" size-md="12" size="12">
                <h3 className="Heading_3 text_sad_purple">{content.get('PROFILE.FORM.VET.TITLE')}</h3>
                <p className="Body_1 text_grey">{content.get('PROFILE.FORM.VET.DESCRIPTION')}</p>
              </IonCol>
              <IonCol size-lg="6" size-md="12" size="12">
                <IonList className="form ion-margin-top">
                  <IonItem lines="none" className="item-no-padding">
                    <IonListHeader className="ion-no-padding">
                      <IonLabel>
                        <h4 className="Heading_5 text_sad_purple">{content.get('PROFILE.FORM.VET.FIELDS.VET_NAME')}</h4>
                      </IonLabel>
                    </IonListHeader>
                  </IonItem>
                  <IonItem lines="none" className="ion-margin-bottom item-no-padding">
                    <IonInput
                      type="text"
                      name="vet_name"
                      onIonChange={handleChange}
                      onIonBlur={handleBlur}
                      value={values.vet_name}
                    >
                    </IonInput>
                    {errors.vet_name && touched.vet_name
                      ? (
                        <IonItem className="ion-margin-bottom input-error-message">
                          <div className="input-error-label"><IonIcon icon={closeCircle} color="danger" slot="start" />{errors.vet_name}</div>
                        </IonItem>
                        )
                      : null}
                  </IonItem>
                  <IonItem lines="none" className="item-no-padding">
                    <IonListHeader className="ion-no-padding">
                      <IonLabel>
                        <h4 className="Heading_5 text_sad_purple">{content.get('PROFILE.FORM.VET.FIELDS.VET_CLINIC')}</h4>
                      </IonLabel>
                    </IonListHeader>
                  </IonItem>
                  <IonItem lines="none" className="ion-margin-bottom item-no-padding">
                    <IonInput
                      type="text"
                      name="vet_clinic"
                      onIonChange={handleChange}
                      onIonBlur={handleBlur}
                      value={values.vet_clinic}
                    >
                    </IonInput>
                    {errors.vet_clinic && touched.vet_clinic
                      ? (
                        <IonItem className="ion-margin-bottom input-error-message">
                          <div className="input-error-label"><IonIcon icon={closeCircle} color="danger" slot="start" />{errors.vet_clinic}</div>
                        </IonItem>
                        )
                      : null}
                  </IonItem>

                  <IonItem lines="none" className="item-no-padding">
                    <IonListHeader className="ion-no-padding">
                      <IonLabel>
                        <h4 className="Heading_5 text_sad_purple">{content.get('PROFILE.FORM.VET.FIELDS.VET_EMAIL')}</h4>
                      </IonLabel>
                    </IonListHeader>
                  </IonItem>
                  <IonItem lines="none" className="ion-margin-bottom item-no-padding">
                    <IonInput
                      type="email"
                      name="vet_email"
                      onIonChange={handleChange}
                      onIonBlur={handleBlur}
                      value={values.vet_email}
                    >
                    </IonInput>
                  </IonItem>

                    {errors.vet_email
                      ? (
                        <IonItem className="ion-margin-bottom input-error-message">
                          <div className="input-error-label"><IonIcon icon={closeCircle} color="danger" slot="start" />{errors.vet_email}</div>
                        </IonItem>
                        )
                      : null}
                </IonList>
              </IonCol>
            </IonRow>
          </IonCardContent>
          <div className={`${styles.card_footer} ion-justify-content-between`} id="footer2">
            <EditableIonButton
              mode="md"
              color="primary"
              fill="outline"
              shape="round"
              onClick={onCancel}
              className="default_button"
            >
              {content.get('PROFILE.FORM.BUTTON.CANCEL')}
            </EditableIonButton>
            <LoaderButton
              type="submit"
              disabled={isSubmitting || !dirty || !isValid}
              color="primary"
              className="default_button loader_button"
              isSubmitting={isSubmitting}
            >
              {content.get('PROFILE.FORM.BUTTON.SAVE')}
            </LoaderButton>
          </div>
          <div id="footerFixed2" className={`${styles.card_footer} ${styles.card_footer_fixed} footer_fixed`}>
                <IonRow className="ion-justify-content-center">
                  <IonCol size-md="10" size="11">
                    <div className="d-flex ion-justify-content-between ml-2 mr-2">
                    <EditableIonButton
                      mode="md"
                      color="primary"
                      fill="outline"
                      shape="round"
                      onClick={onCancel}
                      className="default_button"
                    >
                      {content.get('PROFILE.FORM.BUTTON.CANCEL')}
                    </EditableIonButton>
                    <LoaderButton
                      type="submit"
                      disabled={isSubmitting || !dirty || !isValid}
                      color="primary"
                      className="default_button loader_button"
                      isSubmitting={isSubmitting}
                    >
                      {content.get('PROFILE.FORM.BUTTON.SAVE')}
                    </LoaderButton>
                    </div>
                  </IonCol>
                </IonRow>
            </div>
        </form>
      )}
    </Formik>
  )
}

export default EditProfileForm
