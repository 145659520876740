import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Formik } from 'formik'
import * as Yup from 'yup'
import services from 'util/services'
import LoginFormContent from './LoginFormContent'

const LoginForm = () => {
  const history = useHistory()

  const onSubmit = async (values, setSubmitting) => {
    setLoginFailed(false)
    localStorage.removeItem('user')
    try {
      await services.login(values)
      const user = await services.user() // broken 'cause of org portal
      if (user.id) setSubmitting(false)
      history.push('/profile')
    } catch (e) {
      console.log(e)
      setSubmitting(false)
      setLoginFailed(true)
    }
  }

  const [isLoginFailed, setLoginFailed] = useState(false)

  const registerSchema = Yup.object().shape()
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        email: '',
        password: ''
      }}
      validationSchema={registerSchema}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting)
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting,
        resetForm
      }) => (
        <LoginFormContent
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        values={values}
        isLoginFailed={isLoginFailed}
        errors={errors}
        touched={touched}
        isSubmitting={isSubmitting}
        resetForm={resetForm}
        />
      )}
    </Formik>
  )
}

export default LoginForm
