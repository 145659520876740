import React, { useState } from 'react'
import { useHistory } from 'react-router'

import content from 'util/content'

import { Formik } from 'formik'
import * as Yup from 'yup'
import services from 'util/services'
import FormContent from './RegisterFormContent'

const RegisterForm = () => {
  const history = useHistory()

  const onSubmit = async (values, setSubmitting) => {
    try {
      await services.post('/auth/register', values)
      await services.post('/auth/login', { email: values.email, password: values.password })
      history.push('/profile')
    } catch (e) {
      console.log(e)
      setSubmitting(false)
      setMailNew(false)
    }
    setSubmitting(false)
  }

  const [isMailNew, setMailNew] = useState(true)

  const passwordRequiredError = content.get('REGISTER.FORM.ERROR.PASSWORD')
  const passwordMatchError = content.get('REGISTER.FORM.ERROR.TITLE')

  const passwordValidator = (value) => {
    const errors = {
      hasUpperCase: true,
      hasNumber: true,
      hasMinLength: true
    }

    if (!value || typeof value !== 'string') {
      return errors
    }
    if (!/[A-Z]/.test(value)) {
      errors.hasUpperCase = false
    }
    if (!/\d/.test(value)) {
      errors.hasNumber = false
    }
    if (value.length < 6) {
      errors.hasMinLength = false
    }

    return errors
  }

  const registerSchema = Yup.object({
    first_name: Yup.string().required(
      content.get('REGISTER.FORM.ERROR.FIRSTNAME')
    ),
    last_name: Yup.string().required(
      content.get('REGISTER.FORM.ERROR.LASTNAME')
    ),
    email: Yup.string()
      .email(content.get('REGISTER.FORM.ERROR.EMAIL_VALID'))
      .required(content.get('REGISTER.FORM.ERROR.EMAIL')),
    password: Yup.string()
      .test('passwordValidator', null, function (value) {
        const errors = passwordValidator(value)
        const isValid =
          errors.hasUpperCase && errors.hasNumber && errors.hasMinLength
        return (
          isValid ||
          this.createError({ message: 'Password does not meet requirements' })
        )
      })
      .required(passwordRequiredError),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password'), undefined], passwordMatchError)
      .required(content.get('REGISTER.FORM.ERROR.PASSWORD_CONFIRMATION')),
    term: Yup.bool().oneOf([true]),
    privacy: Yup.bool().oneOf([true])
  })

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        password_confirmation: '',
        term: false,
        privacy: false
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      validationSchema={registerSchema}
      isInitialValid={false}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting)
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        validateField,
        touched,
        setFieldTouched,
        resetForm
      }) => {
        const passwordErrors = passwordValidator(values.password)

        const isFormValid = Object.keys(values).reduce((accumulator, fieldName) => {
          const isTouched = touched[fieldName]
          const isError = errors[fieldName]
          return accumulator & isTouched && !isError
        }, true)

        return (
          <FormContent
            handleSubmit={handleSubmit}
            values={values}
            isMailNew={isMailNew}
            errors={errors}
            setMailNew={setMailNew}
            handleChange={(event) => {
              handleChange(event)
              if (event?.target?.name) {
                if (event.target.name === 'password' && touched.password_confirmation) {
                  validateField('password_confirmation')
                }
                validateField(event.target.name)
                setFieldTouched(event.target.name, true)
              }
            }}
            passwordErrors={passwordErrors}
            isSubmitting={isSubmitting}
            isFormValid={isFormValid}
            resetForm={resetForm}
          />
        )
      }}
    </Formik>
  )
}

export default RegisterForm
