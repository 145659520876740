import React, { useState } from 'react'

import {
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonInput,
  IonLabel,
  IonIcon,
  IonListHeader,
  IonCheckbox,
  IonButton,
  useIonViewWillEnter
} from '@ionic/react'
import {
  closeCircle,
  eye,
  eyeOff,
  checkmarkCircle,
  ellipseOutline,
  logoFacebook,
  logoGoogle
} from 'ionicons/icons'
import LoaderButton from 'components/basepaws/utils/LoaderButton/index'
import EditableNavLink from 'components/basepaws/utils/EditableNavLink'
import EditableIonButton from 'components/basepaws/utils/EditableIonButton'
import content from 'util/content'

import styles from './RegisterForm.module.scss'

const FormContent = ({
  handleSubmit,
  values,
  isMailNew,
  errors,
  handleChange,
  setMailNew,
  passwordErrors,
  isSubmitting,
  resetForm,
  isFormValid = false
}) => {
  const [passwordFieldType, setPasswordFieldType] = useState({
    password: 'password',
    passwordIcon: eye,
    passwordRepeat: 'password',
    passwordRepeatIcon: eye
  })

  useIonViewWillEnter(() => {
    resetForm({
      values: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        password_confirmation: '',
        term: false,
        privacy: false
      },
      errors: {},
      touched: {}
    })
  })

  const changeType = (field) => {
    const currentType = passwordFieldType[field]
    let nextType = 'password'
    let icon = eye
    if (currentType === 'password') {
      nextType = 'text'
      icon = eyeOff
    }
    setPasswordFieldType({
      ...passwordFieldType,
      [field]: nextType,
      [`${field}Icon`]: icon
    })
  }
  return (
    <form
      onSubmit={handleSubmit}
      onKeyDown={(e) => {
        if (
          e.key === 'Enter' &&
          values.first_name !== '' &&
          values.last_name !== '' &&
          values.email !== '' &&
          values.password !== '' &&
          values.password_confirmation !== ''
        ) {
          handleSubmit()
        }
      }}
    >
      <IonRow className="ion-justify-content-center">
        <IonCol size="12">
          <div className={styles.social_media_wrapper}>
            <IonButton
              mode="md"
              color="primary"
              size="full"
              fill="outline"
              shape="round"
              className="default_button"
              href="/2020-09/auth/facebook"
            >
              <IonIcon slot="start" icon={logoFacebook}></IonIcon>
              {content.get('REGISTER.FORM.BUTTONS.FACEBOOK')}
            </IonButton>
            <IonButton
              mode="md"
              color="tertiary"
              size="full"
              fill="outline"
              shape="round"
              className="default_button"
              href="/2020-09/auth/google"
            >
              <IonIcon slot="start" icon={logoGoogle}></IonIcon>
              {content.get('REGISTER.FORM.BUTTONS.GOOGLE')}
            </IonButton>
            <div className="d-flex ion-justify-content-center">
              <hr />
              <p className="Body_1">
                {content.get('REGISTER.FORM.ALTERNATIVE_SIGNUP_MESSAGE')}
              </p>
              <hr />
            </div>
          </div>
          <IonList className="form">
            <IonItem lines="none" className="item-no-padding">
              <IonListHeader className="ion-no-padding">
                <IonLabel>
                  <h4 className="Heading_5 text_sad_purple">
                    {content.get('REGISTER.FORM.FIRSTNAME_LABEL')}
                  </h4>
                </IonLabel>
              </IonListHeader>
            </IonItem>
            <IonItem
              lines="none"
              className={`item-no-padding ${styles.extra_space}`}
            >
              <IonInput
                type="text"
                clearOnEdit={false}
                value={values.first_name}
                name="first_name"
                required
                className={`input-w-icon ${
                  errors.first_name ? 'has-error' : null
                }`}
                onIonInput={handleChange}
                placeholder={content.get(
                  'REGISTER.FORM.FIRSTNAME_PLACEHOLDER',
                  [],
                  true
                )}
              ></IonInput>
              {errors.first_name && (
                <IonItem className="ion-no-margin item-no-padding input-error-message input-error-absolute">
                  <div className="input-error-label">
                    <IonIcon
                      icon={closeCircle}
                      color="danger"
                      slot="start"
                    />
                    {errors.first_name}
                  </div>
                </IonItem>
              )}
            </IonItem>
            <IonItem lines="none" className="item-no-padding">
              <IonListHeader className="ion-no-padding">
                <IonLabel>
                  <h4 className="Heading_5 text_sad_purple">
                    {content.get('REGISTER.FORM.LASTNAME_LABEL')}
                  </h4>
                </IonLabel>
              </IonListHeader>
            </IonItem>
            <IonItem
              lines="none"
              className={`item-no-padding ${styles.extra_space}`}
            >
              <IonInput
                type="text"
                name="last_name"
                clearOnEdit={false}
                value={values.last_name}
                required
                className={`input-w-icon ${
                  errors.last_name ? 'has-error' : null
                }`}
                onIonInput={handleChange}
                placeholder={content.get(
                  'REGISTER.FORM.LASTNAME_PLACEHOLDER',
                  [],
                  true
                )}
              ></IonInput>
              {errors.last_name && (
                <IonItem className="ion-no-margin item-no-padding input-error-message input-error-absolute">
                  <div className="input-error-label">
                    <IonIcon
                      icon={closeCircle}
                      color="danger"
                      slot="start"
                    />
                    {errors.last_name}
                  </div>
                </IonItem>
              )}
            </IonItem>
            <IonItem lines="none" className="item-no-padding">
              <IonListHeader className="ion-no-padding">
                <IonLabel>
                  <h4 className="Heading_5 text_sad_purple">
                    {content.get('REGISTER.FORM.EMAIL_LABEL')}
                  </h4>
                </IonLabel>
              </IonListHeader>
            </IonItem>
            <IonItem
              lines="none"
              className={`item-no-padding ${styles.extra_space}`}
            >
              <IonInput
                className={
                  !isMailNew || errors.email ? 'has-error' : null
                }
                type="email"
                clearOnEdit={false}
                name="email"
                autoComplete="new-email"
                value={values.email}
                required
                placeholder={content.get(
                  'REGISTER.FORM.EMAIL_PLACEHOLDER',
                  [],
                  true
                )}
                onIonFocus={() => setMailNew(true)}
                onIonInput={(event) => {
                  setMailNew(true)
                  handleChange(event)
                }}
              ></IonInput>
              {errors.email && (
                <IonItem className="ion-no-margin item-no-padding input-error-message input-error-absolute">
                  <div className="input-error-label">
                    <IonIcon
                      icon={closeCircle}
                      color="danger"
                      slot="start"
                    />
                    {errors.email}
                  </div>
                </IonItem>
              )}
            </IonItem>
            <IonItem lines="none" className="item-no-padding">
              <IonListHeader className="ion-no-padding">
                <IonLabel>
                  <h4 className="Heading_5 text_sad_purple">
                    {content.get('REGISTER.FORM.PASSWORD_LABEL')}
                  </h4>
                </IonLabel>
              </IonListHeader>
            </IonItem>
            <IonItem lines="none" className="item-no-padding">
              <IonInput
                placeholder="Password"
                clearOnEdit={false}
                className={`input-w-icon ${errors.password_confirmation ? 'has-error' : ''}`}
                type={passwordFieldType.password}
                name="password"
                autocomplete='new-password'
                value={values.password}
                onIonInput={handleChange}
              ></IonInput>
              <IonButton
                className="showPasswordEyeBtn"
                mode="md"
                slot="end"
                fill="clear"
                tabindex="-1"
                onClick={() => changeType('password')}
              >
                <IonIcon
                  slot="icon-only"
                  color="dark"
                  icon={passwordFieldType.passwordIcon}
                ></IonIcon>
              </IonButton>
            </IonItem>
            <IonItem className="ion-margin-bottom item-no-padding">
              <IonRow className="w-100">
                <IonCol size="auto">
                  <EditableIonButton
                    mode="md"
                    className="button-form-check"
                    size="small"
                    fill="clear"
                    disabled
                  >
                    <IonIcon
                      slot="start"
                      icon={
                        !values.password
                          ? ellipseOutline
                          : passwordErrors.hasUpperCase
                            ? checkmarkCircle
                            : closeCircle
                      }
                      color={
                        !values.password
                          ? undefined
                          : passwordErrors.hasUpperCase
                            ? 'success'
                            : 'danger'
                      }
                    />
                    {content.get('REGISTER.FORM.PASSWORD_STRENGHT1')}
                  </EditableIonButton>
                </IonCol>
                <IonCol size="auto">
                  <EditableIonButton
                    mode="md"
                    className="button-form-check ml-2"
                    size="small"
                    fill="clear"
                    disabled
                  >
                    <IonIcon
                      slot="start"
                      icon={
                        !values.password
                          ? ellipseOutline
                          : passwordErrors.hasNumber
                            ? checkmarkCircle
                            : closeCircle
                      }
                      color={
                        !values.password
                          ? undefined
                          : passwordErrors.hasNumber
                            ? 'success'
                            : 'danger'
                      }
                    />
                    {content.get('REGISTER.FORM.PASSWORD_STRENGHT2')}
                  </EditableIonButton>
                </IonCol>
                <IonCol size="auto">
                  <EditableIonButton
                    mode="md"
                    className="button-form-check ml-2"
                    size="small"
                    fill="clear"
                    disabled
                  >
                    <IonIcon
                      slot="start"
                      icon={
                        !values.password
                          ? ellipseOutline
                          : passwordErrors.hasMinLength
                            ? checkmarkCircle
                            : closeCircle
                      }
                      color={
                        !values.password
                          ? undefined
                          : passwordErrors.hasMinLength
                            ? 'success'
                            : 'danger'
                      }
                    />
                    {content.get('REGISTER.FORM.PASSWORD_STRENGHT3')}
                  </EditableIonButton>
                </IonCol>
              </IonRow>
            </IonItem>
            {/* Valitation: Checks chraracters, numbers, etc. */}
            <IonItem lines="none" className="item-no-padding">
              <IonListHeader className="ion-no-padding">
                <IonLabel>
                  <h4 className="Heading_5 text_sad_purple">
                    {content.get('REGISTER.FORM.CONFIRMPASSWORD_LABEL')}
                  </h4>
                </IonLabel>
              </IonListHeader>
            </IonItem>
            <IonItem
              lines="none"
              className={`item-no-padding ${styles.extra_space}`}
            >
              <IonInput
                placeholder={content.get(
                  'REGISTER.FORM.CONFIRMPASSWORD_PLACEHOLDER',
                  [],
                  true
                )}
                className={`input-w-icon ${errors.password_confirmation ? 'has-error' : ''}`}
                type={passwordFieldType.passwordRepeat}
                name="password_confirmation"
                value={values.password_confirmation}
                onIonInput={handleChange}
                clearOnEdit={false}
              ></IonInput>
              <IonButton
                className="showPasswordEyeBtn"
                mode="md"
                slot="end"
                fill="clear"
                tabindex="-1"
                onClick={() => changeType('passwordRepeat')}
              >
                <IonIcon
                  slot="icon-only"
                  color="dark"
                  icon={passwordFieldType.passwordRepeatIcon}
                ></IonIcon>
              </IonButton>
              {errors.password_confirmation && (
                <IonItem className="ion-no-margin item-no-padding input-error-message input-error-absolute">
                  <IonRow>
                    <IonCol>
                      <div className="input-error-label">
                        <IonIcon
                          icon={closeCircle}
                          color="danger"
                          slot="start"
                        />
                        {errors.password_confirmation}
                      </div>
                    </IonCol>
                  </IonRow>
                </IonItem>
              )}
            </IonItem>
            {/* Valitation: Both password should match */}
            <hr className={styles.divider} />
            <IonItem lines="none" className="item-no-padding">
              <p className="Body_2 ion-no-margin">
                {content.get('REGISTER.FORM.TERMS_TEXT')}
                <a
                  href="https://basepaws.com/pages/terms-of-service"
                  className="ml-2 Body_2_bold"
                >
                  {content.get('REGISTER.FORM.TERMS_LINK_TEXT')}
                </a>
                .
              </p>
              <IonCheckbox
                required
                name="term"
                checked={values.term}
                slot="start"
                color="primary"
                onIonChange={(event) => {
                  handleChange({
                    target: { name: 'term', value: event.detail.checked }
                  })
                }}
              />
            </IonItem>
            <IonItem lines="none" className="item-no-padding">
              <p className="Body_2 ion-no-margin">
                {content.get('REGISTER.FORM.TERMS_TEXT2')}
                <a
                  href="https://basepaws.com/pages/privacy-policy"
                  className="ml-2 Body_2_bold"
                >
                  {content.get('REGISTER.FORM.TERMS_LINK_TEXT2')}
                </a>
                .
              </p>
              <IonCheckbox
                required
                name="privacy"
                slot="start"
                color="primary"
                checked={values.privacy}
                onIonChange={(event) => {
                  handleChange({
                    target: {
                      name: 'privacy',
                      value: event.detail.checked
                    }
                  })
                }}
              />
            </IonItem>
          </IonList>
        </IonCol>
        {!isMailNew && (
          <IonItem className="ion-margin-bottom item-no-padding input-error-message">
            <IonRow>
              <IonCol>
                <div className="input-error-label">
                  <IonIcon
                    icon={closeCircle}
                    color="danger"
                    slot="start"
                  />
                  {content.get('REGISTER.FORM.ERROR.EMAIL_EXIST')}
                </div>
              </IonCol>
            </IonRow>
          </IonItem>
        )}
      </IonRow>
      <div className={`${styles.card_footer} ion-justify-content-center`}>
        <LoaderButton
          type="submit"
          disabled={isSubmitting || !isFormValid}
          color="primary"
          className="big_button"
          isSubmitting={isSubmitting}
        >
          Sign up
        </LoaderButton>
      </div>
      <hr className={styles.divider} />
      <p className="ion-text-center Body_1">
        {content.get('REGISTER.FORM.QUESTION_TEXT')}
        <EditableNavLink to="/login" className="ml-2">
          {content.get('REGISTER.FORM.QUESTION_LINK')}
        </EditableNavLink>
      </p>
  </form>
  )
}

export default FormContent
