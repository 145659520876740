const launchDarklyOptions = { streaming: false }

const pagesThatDoNotNeedAuth = ['/register', '/recoverPasswordEmail', '/recoverPasswordEmail/confirmation', '/changePassword']

const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/

const invalidEmailErrorMessage = 'Please input a valid email address'
const invalidPhoneErrorMessage = 'Please input a valid phone number. Letters or special characters are not allowed'

export { launchDarklyOptions, pagesThatDoNotNeedAuth, emailRegex, invalidEmailErrorMessage, invalidPhoneErrorMessage }
