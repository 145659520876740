import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonInput,
  IonLabel,
  IonIcon,
  IonListHeader,
  IonCardContent,
  IonButton,
  useIonViewWillEnter
} from '@ionic/react'
import {
  closeCircle,
  eye,
  eyeOff,
  checkmarkCircle,
  ellipseOutline
} from 'ionicons/icons'
import LoaderButton from 'components/basepaws/utils/LoaderButton/index'
import EditableIonButton from 'components/basepaws/utils/EditableIonButton'

import { Formik } from 'formik'
import * as Yup from 'yup'
import services from 'util/services'
import Toaster from 'components/basepaws/utils/Toaster'
import content from 'util/content'
import styles from './ChangePasswordForm.module.scss'

const ChangePasswordForm = () => {
  const history = useHistory()

  const [saved, setSaved] = useState(false)
  const [passwordFieldType, setPasswordFieldType] = useState({
    currentPassword: 'password',
    currentPasswordIcon: eye,
    password: 'password',
    passwordIcon: eye,
    passwordRepeat: 'password',
    passwordRepeatIcon: eye
  })
  const [password, setPassword] = useState('')
  const [currentPassword, setCurrentPassword] = useState('')
  const [passwordRepeat, setPasswordRepeat] = useState('')
  const [passwordCheck, setPasswordCheck] = useState({
    uppercase: null,
    number: null,
    size: null,
    equal: null
  })
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false)

  useIonViewWillEnter(() => {
    setPassword('')
    setCurrentPassword('')
    setPasswordRepeat('')
  })

  const registerSchema = Yup.object().shape({
    password: Yup.string().test(
      'password-valid',
      '',
      () =>
        passwordCheck.uppercase &&
        passwordCheck.number &&
        passwordCheck.size &&
        passwordCheck.equal
    )
  })

  const onSubmit = async (values, setSubmitting, resetForm) => {
    setSubmitting(true)
    try {
      const data = await services
        .post('/auth/changePassword', {
          currentPassword: currentPassword,
          password: password,
          password_repeat: passwordRepeat
        })
        .then(() => {
          setSaved(true)
          setSubmitting(false)
          resetForm()
          setPassword('')
          setCurrentPassword('')
          setPasswordRepeat('')
          history.push('/profile')
        })
    } catch (e) {
      setSubmitting(false)
      if (e.errors[0] === 'invalid password') {
        setIsPasswordInvalid(true)
      }
      console.log(e)
    }
  }

  const onChangePassword = (e) => {
    const passwordLocal = e.target.value || ''

    const check = passwordCheck
    check.uppercase = /[A-Z]/.test(passwordLocal)
    check.number = /[0-9]/.test(passwordLocal)
    check.size = passwordLocal.length >= 6
    check.equal = passwordLocal === passwordRepeat

    setPassword(passwordLocal)
    setPasswordCheck(check)
  }

  const onChangePasswordRepeat = (e) => {
    const passwordLocal = e.target.value || ''

    const passwordEqual = passwordLocal === password
    setPasswordRepeat(passwordLocal)
    setPasswordCheck({
      ...passwordCheck,
      equal: passwordEqual
    })
  }

  const changeType = (field) => {
    setPasswordFieldType((prevState) => ({
      ...prevState,
      [field]: prevState[field] === 'password' ? 'text' : 'password',
      [`${field}Icon`]: prevState[`${field}Icon`] === eye ? eyeOff : eye
    }))
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        currentPassword: currentPassword,
        password: password,
        passwordRepeat: passwordRepeat
      }}
      validationSchema={registerSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        onSubmit(values, setSubmitting, resetForm)
      }}
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        isSubmitting,
      }) => (
        <form
          onSubmit={handleSubmit}
          onKeyDown={(e) => {
            if (
              e.key === 'Enter' &&
              values.currentPassword !== '' &&
              values.password !== '' &&
              values.passwordRepeat !== ''
            ) {
              handleSubmit()
            }
          }}
        >
          <Toaster
            status={saved}
            onDidDismiss={() => {
              setSaved(false)
            }}
            header={content.get('CHANGEPASSWORD.TOAST.TITLE')}
            message={content.get('CHANGEPASSWORD.TOAST.TEXT')}
          />
          <IonCardContent className={styles.card_content}>
            <IonRow className="ion-justify-content-center">
              <IonCol size="12">
                <IonList className="form ion-margin-top">
                  <IonItem lines="none" className="item-no-padding">
                    <IonListHeader className="ion-no-padding ion-margin-bottom">
                      <IonLabel>
                        <h4 className="Heading_5 text_sad_purple">
                          {content.get('CHANGEPASSWORD.FORM.TITLE1')}
                        </h4>
                        <p className="Body_3 text_dark_grey">
                          {content.get('CHANGEPASSWORD.FORM.SUBTITLE1')}
                        </p>
                      </IonLabel>
                    </IonListHeader>
                  </IonItem>

                  <IonItem
                    lines="none"
                    className="ion-margin-bottom item-no-padding"
                  >
                    <IonInput
                      className="input-w-icon"
                      type={passwordFieldType.currentPassword}
                      name="currentPassword"
                      clearOnEdit={false}
                      value={currentPassword}
                      onIonFocus={() => setIsPasswordInvalid(false)}
                      onIonChange={(e) => setCurrentPassword(e.target.value)}
                    />
                    <IonButton
                      mode="md"
                      fill="clear"
                      tabindex="-1"
                      onClick={() => changeType('currentPassword')}
                      style={{
                        position: 'absolute',
                        right: '10px',
                        zIndex: 1000
                      }}
                    >
                      <IonIcon
                        slot="icon-only"
                        color="dark"
                        icon={passwordFieldType.currentPasswordIcon}
                      />
                    </IonButton>

                    {errors.currentPassword && touched.currentPassword
                      ? (
                      <IonItem className="ion-margin-bottom input-error-message">
                        <div className="input-error-label">
                          <IonIcon
                            icon={closeCircle}
                            color="danger"
                            slot="start"
                          />
                          {errors.born_on}
                        </div>
                      </IonItem>
                        )
                      : null}
                  </IonItem>
                  <IonItem lines="none" className="item-no-padding">
                    <IonListHeader className="ion-no-padding ion-margin-bottom">
                      <IonLabel>
                        <h4 className="Heading_5 text_sad_purple">
                          {content.get('CHANGEPASSWORD.FORM.TITLE2')}
                        </h4>
                        <p className="Body_3 text_dark_grey">
                          {content.get('CHANGEPASSWORD.FORM.SUBTITLE2')}
                        </p>
                      </IonLabel>
                    </IonListHeader>
                  </IonItem>
                  <IonItem lines="none" className="item-no-padding">
                    <IonInput
                      className="input-w-icon"
                      type={passwordFieldType.password}
                      name="password"
                      value={password}
                      onIonChange={onChangePassword}
                    />
                    <IonButton
                      mode="md"
                      fill="clear"
                      tabindex="-1"
                      onClick={() => changeType('password')}
                      style={{
                        position: 'absolute',
                        right: '10px',
                        zIndex: 1000
                      }}
                    >
                      <IonIcon
                        slot="icon-only"
                        color="dark"
                        icon={passwordFieldType.passwordIcon}
                      />
                    </IonButton>
                  </IonItem>
                  <IonItem className="ion-margin-bottom item-no-padding">
                    <IonRow className="w-100">
                      <IonCol size="auto">
                        <EditableIonButton
                          mode="md"
                          className="button-form-check"
                          size="small"
                          fill="clear"
                          disabled
                        >
                          {passwordCheck.uppercase === null && (
                            <IonIcon slot="start" icon={ellipseOutline} />
                          )}
                          {passwordCheck.uppercase === true && (
                            <IonIcon
                              slot="start"
                              icon={checkmarkCircle}
                              color="success"
                            />
                          )}
                          {passwordCheck.uppercase === false && (
                            <IonIcon
                              slot="start"
                              icon={closeCircle}
                              color="danger"
                            />
                          )}
                          {content.get(
                            'CHANGEPASSWORD.FORM.PASSWORD_STRENGHT1'
                          )}
                        </EditableIonButton>
                      </IonCol>
                      <IonCol size="auto">
                        <EditableIonButton
                          mode="md"
                          className="button-form-check ml-2"
                          size="small"
                          fill="clear"
                          disabled
                        >
                          {passwordCheck.number === null && (
                            <IonIcon slot="start" icon={ellipseOutline} />
                          )}
                          {passwordCheck.number === true && (
                            <IonIcon
                              slot="start"
                              icon={checkmarkCircle}
                              color="success"
                            />
                          )}
                          {passwordCheck.number === false && (
                            <IonIcon
                              slot="start"
                              icon={closeCircle}
                              color="danger"
                            />
                          )}
                          {content.get(
                            'CHANGEPASSWORD.FORM.PASSWORD_STRENGHT2'
                          )}
                        </EditableIonButton>
                      </IonCol>
                      <IonCol size="auto">
                        <EditableIonButton
                          mode="md"
                          className="button-form-check ml-2"
                          size="small"
                          fill="clear"
                          disabled
                        >
                          {passwordCheck.size === null && (
                            <IonIcon slot="start" icon={ellipseOutline} />
                          )}
                          {passwordCheck.size === true && (
                            <IonIcon
                              slot="start"
                              icon={checkmarkCircle}
                              color="success"
                            />
                          )}
                          {passwordCheck.size === false && (
                            <IonIcon
                              slot="start"
                              icon={closeCircle}
                              color="danger"
                            />
                          )}
                          {content.get(
                            'CHANGEPASSWORD.FORM.PASSWORD_STRENGHT3'
                          )}
                        </EditableIonButton>
                      </IonCol>
                    </IonRow>
                  </IonItem>
                  <IonItem lines="none" className="item-no-padding">
                    <IonListHeader className="ion-no-padding ion-margin-bottom">
                      <IonLabel>
                        <h4 className="Heading_5 text_sad_purple">
                          {content.get('CHANGEPASSWORD.FORM.TITLE3')}
                        </h4>
                        <p className="Body_3 text_dark_grey">
                          {content.get('CHANGEPASSWORD.FORM.SUBTITLE3')}
                        </p>
                      </IonLabel>
                    </IonListHeader>
                  </IonItem>
                  <IonItem lines="none" className="item-no-padding">
                    <IonInput
                      className="input-w-icon"
                      type={passwordFieldType.passwordRepeat}
                      name="passwordRepeat"
                      value={passwordRepeat}
                      onIonChange={onChangePasswordRepeat}
                    />
                    <IonButton
                      mode="md"
                      fill="clear"
                      tabindex="-1"
                      onClick={() => changeType('passwordRepeat')}
                      style={{
                        position: 'absolute',
                        right: '10px',
                        zIndex: 1000
                      }}
                    >
                      <IonIcon
                        slot="icon-only"
                        color="dark"
                        icon={passwordFieldType.passwordRepeatIcon}
                      />
                    </IonButton>
                  </IonItem>
                  {passwordCheck.equal != null && !passwordCheck.equal && (
                    <IonItem className="ion-margin-bottom item-no-padding input-error-message">
                      <IonRow>
                        <IonCol>
                          <div className="input-error-label">
                            <IonIcon
                              icon={closeCircle}
                              color="danger"
                              slot="start"
                            />
                            {content.get('CHANGEPASSWORD.FORM.ERROR.TITLE')}
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonItem>
                  )}
                  {isPasswordInvalid && (
                    <IonItem className="ion-margin-bottom item-no-padding input-error-message">
                      <IonRow>
                        <IonCol>
                          <div className="input-error-label">
                            <IonIcon
                              icon={closeCircle}
                              color="danger"
                              slot="start"
                            />
                            {content.get('CHANGEPASSWORD.FORM.ERROR2.TITLE')}
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonItem>
                  )}
                </IonList>
              </IonCol>
            </IonRow>
          </IonCardContent>
          <div className={`${styles.card_footer} ion-justify-content-between`}>
            <EditableIonButton
              mode="md"
              type="submit"
              color="primary"
              fill="outline"
              shape="round"
              className="default_button"
              onclick={() => {
                history.push('/profile')
              }}
            >
              {content.get('CHANGEPASSWORD.FORM.CTA_LEFT.TITLE')}
            </EditableIonButton>
            <LoaderButton
              type="submit"
              disabled={isSubmitting}
              color="primary"
              className="default_button"
              isSubmitting={isSubmitting}
            >
              {content.get('CHANGEPASSWORD.FORM.CTA_RIGHT.TITLE')}
            </LoaderButton>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default ChangePasswordForm
